import React from 'react'
import { FormProvider, FormProviderProps, SubmitHandler } from 'react-hook-form'

type Props<Data> = FormProviderProps<Data> & {
  onSubmit: SubmitHandler<Data>
  className?: string
}

export function Form<Data>({ children, onSubmit, className, ...methods }: Props<Data>) {
  const { handleSubmit } = methods

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={className}>
        {children}
      </form>
    </FormProvider>
  )
}
