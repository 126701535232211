import { FC } from 'react'
import dynamic, { Loader } from 'next/dynamic'
import { i18n } from 'next-i18next'
import { DateTime, Interval } from 'luxon'
import humanizeDuration from 'humanize-duration'
import { FieldValues, Path, UseFormSetError } from 'react-hook-form'
import { MoneyFragment, Model } from '@foros-fe/graphql'

export function clientOnlyComponent<Props = {}>(component: FC<Props>) {
  return dynamic(Promise.resolve(component), { ssr: false })
}

export function clientOnlyModule<Props>(loader: Loader<Props>) {
  return dynamic<Props>(loader, { ssr: false })
}

export function formatMoney(money?: null, options?: Intl.NumberFormatOptions): undefined
export function formatMoney(money: MoneyFragment, options?: Intl.NumberFormatOptions): string
export function formatMoney<Money extends MoneyFragment | null>(
  money?: Money,
  options?: Intl.NumberFormatOptions
): string | undefined
export function formatMoney(money?: MoneyFragment | null, options: Intl.NumberFormatOptions = {}) {
  if (!money) return

  const { amount, currency } = money
  const formatter = Intl.NumberFormat(i18n?.language, {
    style: 'currency',
    currency: currency.isoCode,
    ...options,
  })

  return formatter.format(parseFloat(amount))
}

export function formatValueMoney(
  money?: MoneyFragment | null,
  options: Intl.NumberFormatOptions = {}
) {
  if (!money) {
    const t = i18n?.getFixedT(i18n.language, 'common')

    return t?.('calculating')
  }

  return formatMoney(money, options)
}

export function formatArea(area?: number | null) {
  if (typeof area !== 'number') return

  const t = i18n?.getFixedT(i18n.language, 'common')

  return `${area.toFixed(2)} ${t?.('hectare')}`
}

export function formatVolume(volume?: number | null, units?: string) {
  if (typeof volume !== 'number') return

  const t = i18n?.getFixedT(i18n.language, 'common')

  return `${volume.toFixed(2)} ${units ?? t?.('cubicMeters')}`
}

export function getErrorTranslations(enumName: string, errors: string[]) {
  const t = i18n?.getFixedT(i18n.language, 'enum')

  return {
    types: errors.reduce((accumulator, error) => {
      accumulator[error] = t?.(`${enumName}.${error}`)

      return accumulator
    }, {} as Record<string, string | undefined>),
  }
}

export function arrayToChunks<Type>(array: Type[], perChunk: number) {
  return array.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    resultArray[chunkIndex] ??= []
    resultArray[chunkIndex].push(item)

    return resultArray
  }, [] as Type[][])
}

export function humanDurationString(endTime: string, startTime?: string) {
  const now = startTime ? DateTime.fromISO(startTime) : DateTime.local()
  const endDateTime = DateTime.fromISO(endTime)
  const duration = Interval.fromDateTimes(now.startOf('second'), endDateTime.startOf('second'))
    .toDuration()
    .toMillis()

  return humanizeDuration(duration, {
    language: i18n?.language,
    fallbacks: ['en'],
    largest: 3,
  })
}

export function endTimestamp(endTime: string) {
  return DateTime.fromISO(endTime).toFormat('yyyy-MM-dd HH:mm (ZZZZ)')
}

export interface SortOption {
  label: string
  value: string
}

export function enumToOption(enumObject: object): SortOption[] {
  return Object.values(enumObject).map((value) => ({ label: value, value }))
}

type APIErrorValue = string | string[] | null

export function setFormFieldsErrorTranslations<
  FormFields extends FieldValues,
  Error extends Partial<Record<keyof FormFields, APIErrorValue>>
>(
  setError: UseFormSetError<FormFields>,
  error: Error,
  fieldEnumMap: { [key in keyof FormFields & keyof Error]?: string }
) {
  const entries = Object.entries(fieldEnumMap) as Array<[Path<FormFields>, string]>

  entries.forEach(([field, enumName]) =>
    setFormFieldErrorTranslation(setError, field, error[field], enumName)
  )
}

export function setFormFieldErrorTranslation<FormFields extends FieldValues>(
  setError: UseFormSetError<FormFields>,
  field: Path<FormFields>,
  errors: APIErrorValue | undefined,
  enumName: string
) {
  if (Array.isArray(errors)) {
    setError(field, getErrorTranslations(enumName, errors))
  }
}

export function removeDuplicateObjects<Type extends Model[]>(array: Type) {
  return array.filter(
    (item, i): item is ArrayElement<Type> => array.findIndex(({ id }) => item.id === id) === i
  )
}

export function reduceNestedUniqueLists<Type extends Model = Model>(
  items: Type[],
  listNames: Array<KeysOfType<Type, Array<Model>>>
) {
  const lists = items.reduce(
    (accumulator, item) => {
      listNames.forEach((name, index) => accumulator[index].push(...item[name]))

      return accumulator
    },
    listNames.map((name) => new Array<Type[typeof name]>())
  )

  return Object.fromEntries(
    lists.map((list, index) => [listNames[index], removeDuplicateObjects(list)])
  ) as Pick<Type, KeysOfType<Type, Array<any>>>
}
