import { InputHTMLAttributes, useState, forwardRef } from 'react'
import { FieldError, get, useFormContext } from 'react-hook-form'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import { ErrorMessages } from '../ErrorMessages'

type Props = Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'prefix'> & {
  name: string
  prefix?: React.ReactNode
  label?: string
  error?: FieldError
  hint?: string
  labelHint?: {
    title: string
    onClick?: () => void
  }
}

const Container = tw.div`flex flex-col flex-1 items-start`
const PrefixContainer = tw.div`mr-2`
const InputContainer = tw.div<{ disabled?: boolean; $error?: object }>`
  flex
  items-center
  ${({ disabled }) => (disabled ? 'bg-gray-200' : 'bg-white')}
  px-3
  md:px-4
  py-2
  h-10
  md:h-11
  border
  rounded-lg
  ${({ $error }) => ($error ? 'border-red-500' : 'border-gray-400')}
  focus-within:border-secondary-500
  w-full
`
const LabelContainer = tw.div`
  flex
  self-stretch
  items-center
  justify-between
  mb-1
  md:mb-2
`
const Label = tw.label`
  text-sm
  font-semibold
  text-gray-800
`
const Input = tw.input`
  text-sm
  md:text-base
  text-gray-800
  bg-transparent
  placeholder-gray-600
  w-full
`
const PsSuffix = tw.p`
  text-primary-500
  hover:underline
  cursor-pointer
  text-sm
  md:text-md
  ml-2
`
const Hint = tw.p`
  text-sm
  text-gray-600
`

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    { label, name, className, type = 'text', disabled, prefix, error, hint, labelHint, ...props },
    ref
  ) => {
    const { t } = useTranslation()
    const [inputVisible, setVisible] = useState(false)
    const context = useFormContext()

    const fieldError = error || get(context?.formState.errors, name)

    return (
      <Container className={className}>
        {(label || labelHint) && (
          <LabelContainer>
            {label && <Label>{label}</Label>}
            {labelHint && (
              <PsSuffix {...labelHint} className='font-semibold'>
                {labelHint.title}
              </PsSuffix>
            )}
          </LabelContainer>
        )}
        <InputContainer disabled={disabled} $error={fieldError}>
          {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
          <Input
            name={name}
            type={inputVisible ? 'text' : type}
            disabled={disabled}
            ref={ref}
            {...props}
          />
          {type === 'password' && (
            <PsSuffix onClick={() => setVisible(!inputVisible)}>
              {inputVisible ? t('input.hide') : t('input.show')}
            </PsSuffix>
          )}
        </InputContainer>
        {<ErrorMessages error={fieldError} fallback={hint ? <Hint>{hint}</Hint> : null} />}
      </Container>
    )
  }
)
