import { FC, HTMLAttributes, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'
import tw from 'tailwind-styled-components'

type Props = HTMLAttributes<HTMLParagraphElement> & {
  error?: FieldError
  fallback?: ReactNode
}

const Error = tw.p`
  leading-6
  text-red-500
  text-sm
`

export const ErrorMessages: FC<Props> = ({ error, fallback, ...props }) =>
  error ? (
    error.types ? (
      <>
        {Object.entries(error.types).map(([key, value]) => (
          <Error key={key} {...props}>
            &nbsp;{value}
          </Error>
        ))}
      </>
    ) : error.message ? (
      <Error {...props}>&nbsp;{error.message}</Error>
    ) : null
  ) : (
    <>{fallback}</>
  )
