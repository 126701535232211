import tw from 'tailwind-styled-components'

export const Card = tw.div`
  rounded-lg
  bg-white
  flex
  flex-col
  relative
  p-4
`
